import { Title } from '@solidjs/meta';
import { Icon } from '@troon/icons';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

export default function Styleguide() {
	return (
		<>
			<Title>Styleguide</Title>
			<h1 class="mb-4 text-4xl font-semibold">Styleguide</h1>

			<div class="mb-8 grid grid-cols-9 gap-4">
				<StyleCard title="Logo">
					<Icon name="logo" />
				</StyleCard>

				<StyleCard title="Logo / Square">
					<div class="size-24">
						<Icon name="logo-square" />
					</div>
				</StyleCard>
			</div>
		</>
	);
}

function StyleCard(props: ParentProps<{ title: string; class?: string }>) {
	return (
		<div
			class={twMerge(
				'col-span-12 flex flex-col gap-4 rounded-md border border-neutral-500 px-8 py-4 md:col-span-6 lg:col-span-3',
				props.class ?? '',
			)}
		>
			<h3 class="font-semibold">{props.title}</h3>
			<div class="flex grow flex-col items-stretch justify-center gap-4">{props.children}</div>
		</div>
	);
}
